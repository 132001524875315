<template>
  <div v-if="detailData">
    <div class="key-visual">
      <div class="contents">
        <div class="txt" :style="`color:${detailData.detailFont}`">
          <span v-html="detailData.project" />
          <br />
          {{ detailData.projectServiceName }}
        </div>
        <div class="info">
          <span class="name" :style="`color:${detailData.detailFont}`">
            {{ detailData.projectClientName }}
          </span>
          <span class="date" :style="`color:${detailData.detailFont}`">
            <!--            {{ detailData.releaseDate | dateFormat }}-->
            {{ detailData.releaseDate }}
          </span>
          <span class="desc" :style="`color:${detailData.detailFont}`">
            {{ detailData.projectType }}
          </span>
        </div>
      </div>
      <div
        class="bg"
        :style="{
          backgroundImage: `url(${imgUrl(detailData.moDetailImagePhysicalName)})`
        }"
      ></div>
    </div>

    <div
      class="project-desc"
      :style="{
        backgroundImage: `url(${imgUrl(
          detailData.projectDesc.worksFieldImageList[0].moImagePhysicalName
        )})`
      }"
    >
      <div class="inner" v-html="handleSpace(detailData.projectDesc.fieldContents)"></div>
    </div>

    <div
      class="project-goal"
      :style="{
        backgroundImage: `url(${imgUrl(
          detailData.projectGoal.worksFieldImageList[0].moImagePhysicalName
        )})`
      }"
    >
      <div class="inner">
        <span class="tit" :style="`color:${detailData.projectGoal.color}`">Project Goal</span>
        <img
          :src="imgUrl(detailData.projectGoal.worksFieldImageList[1].moImagePhysicalName)"
          class="logo"
          alt=""
        />
        <span
          class="desc"
          v-html="handleSpace(detailData.projectGoal.fieldContents)"
          :style="`color:${detailData.projectGoal.color}`"
        ></span>
      </div>
    </div>

    <div
      v-for="(item, index) in detailData.projectInfo"
      :key="`info${index}`"
      class="project-info"
      :style="{backgroundColor: item.bgColor, color: item.color}"
    >
      <div class="contents">
        <div
          class="tit"
          :style="{color: item.color}"
          v-html="handleSpace(item.fieldTitleOne)"
        ></div>
        <div
          class="desc"
          :style="{color: item.color}"
          v-html="handleSpace(item.fieldContents)"
        ></div>
        <img :src="imgUrl(item.worksFieldImageList[0].moImagePhysicalName)" class="logo" alt="" />
      </div>
    </div>

    <div class="contents" v-if="detailData.designConcept.length > 0">
      <h2 class="section-title">Design Concept</h2>
    </div>

    <div
      v-for="(item, index) in detailData.designConcept"
      :key="`concept${index}`"
      class="project-info design-concept"
      :style="{backgroundColor: item.bgColor, color: item.color}"
    >
      <div class="contents">
        <div
          class="tit"
          :style="{color: item.color}"
          v-html="handleSpace(item.fieldTitleOne)"
        ></div>
        <div
          class="desc"
          :style="{color: item.color}"
          v-html="handleSpace(item.fieldContents)"
        ></div>
        <img :src="imgUrl(item.worksFieldImageList[0].moImagePhysicalName)" class="logo" alt="" />
      </div>
    </div>

    <div class="interview" v-if="detailData.interview">
      <div class="inner">
        <div class="info">
          <h2 class="section-title">PROJECT INTERVIEW</h2>
          <p
            v-if="detailData.interview.interviewContents"
            v-html="detailData.interview.interviewContents"
          ></p>
          <ul>
            <li v-for="(item, index) in detailData.creditList" :key="index">
              <strong>{{ item.creditPosition }}</strong>
              <span>{{ item.creditName }}</span>
            </li>
          </ul>
        </div>
        <!-- <img :src="require('@/assets/images/@temp/work_detail3.png')" alt="" />-->
        <img
          class="interviewer"
          v-if="detailData.interview.intervieweeMoImagePhysicalName"
          :src="imgUrl(detailData.interview.intervieweeMoImagePhysicalName)"
          alt=""
        />
      </div>
    </div>
  </div>
</template>
<script>
  import {apiWorksDetail} from "@/api";
  import {Power0, TweenMax} from "gsap";
  import {mapGetters} from "vuex";
  import {commonMethods} from "@/utils/common-methods";

  export default {
    name: "worksDetail",
    /*metaInfo() {
      return {
        title: this.detailData?.projectServiceName || "Works"
      };
    },*/
    data() {
      return {
        scene: null,
        detailData: null
      };
    },
    computed: {
      ...mapGetters(["GE_CONT_HEIGHT"])
    },
    watch: {
      GE_CONT_HEIGHT() {
        if (this.$el.offsetParent) {
          this.init();
        }
      }
    },
    activated() {
      this.init();
    },
    mixins: [commonMethods],
    methods: {
      init() {
        this.scene?.destroy(true);
        const keyVisual = document.querySelector(".key-visual");
        if (!keyVisual) return;
        const tween = TweenMax.to(keyVisual.querySelector(".bg"), 1, {
          y: keyVisual.clientHeight,
          ease: Power0.easeInOut
        });
        const tween2 = TweenMax.to(keyVisual.querySelector(".txt"), 1, {
          y: keyVisual.clientHeight + keyVisual.querySelector(".txt").clientHeight,
          ease: Power0.easeInOut
        });
        const tween3 = TweenMax.to(keyVisual.querySelector(".info"), 1, {
          y: keyVisual.clientHeight + 200,
          ease: Power0.easeInOut
        });
        this.scene = this.$scrollmagic
          .scene({
            triggerElement: keyVisual,
            triggerHook: 0,
            duration: keyVisual.clientHeight * 2
          })
          .setTween([tween, tween2, tween3]);
        this.$scrollmagic.addScene(this.scene);
      },
      async fetchData() {
        try {
          const {
            data: {data: response}
          } = await apiWorksDetail(this.$route.params.id);
          console.log(response);
          this.detailData = response;
          console.log(this.detailData);
          this.$nextTick(() => {
            this.init();
          });
        } catch (error) {
          console.log(error);
        }
      }
    },
    created() {
      this.fetchData();
    },
    deactivated() {
      this.scene?.destroy(true);
    }
  };
</script>
<style lang="scss" scoped>
  .interview {
    padding: 35px 0 0;
    background-color: #f9f9f9;
    .inner {
      padding: 0 20px;
      .section-title {
        margin: 0;
      }
      .info {
        padding: 0 0 30px;
        word-break: keep-all;
        p {
          margin-top: 32px;
          font-size: 12px;
          line-height: 19px;
          letter-spacing: -0.05em;
          strong {
            font-weight: bold;
          }
        }
        ul {
          margin-top: 29px;
          font-size: 11px;
          line-height: 20px;
          color: #6e6e6e;
          li {
            display: flex;
            padding: 5px 0;
            letter-spacing: -0.03em;
            strong {
              flex: 0 0 150px;
              @include montserrat;
            }
          }
        }
      }
      .interviewer {
        display: block;
        width: 220px;
        margin: 0 auto;
      }
    }
  }
  .section-title {
    margin-top: 35px;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    @include montserrat;
    text-transform: uppercase;
  }
  .key-visual {
    position: relative;
    height: 100vh;
    overflow: hidden;
    min-width: $globalWidth;
    .contents {
      z-index: 2;
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      justify-content: center;
      align-items: flex-start;
      height: 100vh;
      position: relative;
      .txt {
        @include montserrat;
        font-weight: 700;
        font-size: 36px;
        color: #fff;
        line-height: 45px;
        word-break: keep-all;
      }
      .info {
        display: flex;
        flex-wrap: wrap;
        margin-top: 123px;
        color: #fff;
        font-size: 11px;
        line-height: 26px;
        .name {
          flex: 0 0 auto;
          margin-right: 23px;
          font-family: "Noto Sans KR", Montserrat, sans-serif;
        }
        .date {
          @include montserrat;
          flex: 0 0 auto;
          margin-right: 23px;
        }
        .desc {
          @include montserrat;
          flex: 1 0 auto;
        }
      }
    }
    .bg {
      position: absolute;
      top: 0;
      left: 0;
      min-width: $globalWidth;
      height: 100%;
      width: 100%;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: 50% 50%;
    }
  }
  .project-desc {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    height: 475px;
    line-height: 28px;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: -0.05em;
    background-position: center center;
    background-size: cover;
    word-break: keep-all;
    .inner {
      padding: 0 20px;
    }
  }
  .project-goal {
    display: flex;
    align-items: flex-start;
    position: relative;
    box-sizing: border-box;
    min-height: 217vw;
    padding: 60px 20px 168px;
    background-size: cover;
    background-position: center;
    &:before {
      content: "";
      height: 1px;
      display: block;
    }
    .inner {
      text-align: center;
      .tit {
        position: absolute;
        top: 35px;
        left: 20px;
        @include montserrat;
        display: block;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        text-transform: uppercase;
        color: #fff;
        text-align: left;
      }
      .logo {
        margin: 50px 0 0;
      }
      .desc {
        display: block;
        margin: 55px 0 0;
        font-size: 18px;
        line-height: 28px;
        letter-spacing: -0.05em;
        color: #fff;
        word-break: keep-all;
      }
    }
  }
  .project-info {
    padding: 28px 0;
    .contents {
      color: #000;
      .tit {
        text-transform: uppercase;
        @include montserrat;
        display: block;
        position: relative;
        box-sizing: border-box;
        font-size: 12px;
        line-height: 34px;
        letter-spacing: -0.05em;
        padding-left: 20px;
        &:before {
          content: "";
          position: absolute;
          top: 50%;
          left: 0;
          height: 10px;
          width: 10px;
          margin-top: -5px;
          border-radius: 50%;
          background: $red;
        }
      }
      .desc {
        display: block;
        margin: 4px 0 0;
        padding-left: 20px;
        font-weight: 700;
        font-size: 14px;
        line-height: 23px;
        word-break: keep-all;
      }
      img {
        margin-top: 40px;
      }
    }
    &.design-concept {
      padding: 24px 0;
    }
  }
</style>
